var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "paylink-invoice",
      attrs: { id: `invoice-${_vm.invoice.erp_id}` },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-align-left", attrs: { sm: "6", sx: "12" } },
            [
              _c("p", [
                _vm._v("Invoice: "),
                _c("b", [_vm._v(_vm._s(_vm.invoice.reference_number))]),
              ]),
              _c("p", [
                _vm._v("Minimum Payment: "),
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.invoice.min_payment.display_amount_with_currency)
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v("Total Balance: "),
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.invoice.total_due.display_amount_with_currency)
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v("Due Date: "),
                _c("b", [_vm._v(_vm._s(_vm.due_date))]),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.invoice.memo))]),
            ]
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoice.selected,
                  expression: "invoice.selected",
                },
              ],
              attrs: { sm: "6", sx: "12" },
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "paylink-invoice-input-row",
                  attrs: { block: "" },
                },
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "pay-min-amount-due",
                          attrs: {
                            variant: "outline-success",
                            name: "payment amount type",
                          },
                          on: { click: _vm.handlePayAmountDue },
                        },
                        [_vm._v(" Minimum ")]
                      ),
                      _c(
                        "b-btn",
                        {
                          staticClass: "pay-balance",
                          attrs: {
                            variant: "outline-primary",
                            name: "payment amount type",
                          },
                          on: { click: _vm.handlePayFullBalance },
                        },
                        [_vm._v(" Total ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "paylink-invoice-input-row",
                  attrs: { block: "" },
                },
                [
                  _c("b-form-input", {
                    staticClass: "amount-range",
                    attrs: {
                      type: "range",
                      min: _vm.invoice.min_payment.dollar_amount,
                      max: _vm.invoice.total_due.dollar_amount,
                    },
                    on: { input: _vm.handlePaymentAmountInput },
                    model: {
                      value: _vm.amount_being_paid,
                      callback: function ($$v) {
                        _vm.amount_being_paid = $$v
                      },
                      expression: "amount_being_paid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "paylink-invoice-input-row",
                  attrs: { block: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_vm._v(" $ ")]
                      ),
                      _c("b-form-input", {
                        staticClass: "amount-input",
                        attrs: {
                          type: "number",
                          min: _vm.invoice.min_payment.dollar_amount,
                          max: _vm.invoice.total_due.dollar_amount,
                          step: "0.01",
                        },
                        on: { input: _vm.handlePaymentAmountInput },
                        model: {
                          value: _vm.amount_being_paid,
                          callback: function ($$v) {
                            _vm.amount_being_paid = $$v
                          },
                          expression: "amount_being_paid",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.invoice.selected,
                  expression: "!invoice.selected",
                },
              ],
              staticClass: "line-items line-items-mini",
              attrs: { sm: "6", sx: "12" },
            },
            [
              _c("b-table", {
                attrs: {
                  id: `inv-${_vm.invoice.erp_id}-line-items`,
                  items: _vm.invoice.line_items,
                  fields: ["name", "amount", "quantity"],
                  responsive: true,
                  "empty-text": "No Line Items",
                  "show-empty": "",
                  striped: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(amount)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.item.amount.display_amount_with_currency
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoice.selected,
                  expression: "invoice.selected",
                },
              ],
              staticClass: "line-items line-items-full",
              attrs: { sm: "12", sx: "12" },
            },
            [
              _c("b-table", {
                attrs: {
                  id: `inv-${_vm.invoice.erp_id}-line-items`,
                  items: _vm.invoice.line_items,
                  fields: ["name", "amount", "quantity", "description"],
                  responsive: true,
                  "empty-text": "No Line Items",
                  "show-empty": "",
                  striped: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(amount)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.item.amount.display_amount_with_currency
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
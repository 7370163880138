import { Gateway } from '@omnimerchant/types'
import { AlertsInterface, ProgressOverlayInterface } from "@/types/ui_types"


export type AlertDetails =
  Error | Gateway.GatewayTypes.ResponseType | any


export type AlertTypes = 'danger' | 'success' | 'warning'


export type AlertMessageType = {
  message: string,
  type: AlertTypes,
  details?: AlertDetails,
}


const ViewStore: {
  [key: string]: any,
  // TODO: create a separate alert services to decouple alerts
  // from UI
  alert: AlertsInterface & {
    add: (message: string, type: AlertTypes, details?: AlertDetails) => void,
    alerts: Map<string, AlertMessageType>,
    alerts_array: AlertMessageType[],
    getAlertsAsArray: () => AlertMessageType[],
    remove: (message: string) => void,
    removeAll: () => void,
  }
  // TODO: create a separate progress services to decouple progress
  // from UI
  progressbar: ProgressOverlayInterface & {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _is_visibile: boolean
    message: string
    changeMessage: (message: string) => void
    visible: () => boolean
  },
} = {
  alert: {
    add: function(
      message: string, type: AlertTypes, details?: AlertDetails,
    ) {
      this.alerts.set(
        message,
        {
          message: message,
          type: type,
          details,
        }
      )
      this.alerts_array.push({
        message: message,
        type: type,
        details,
      })
    },

    addDanger: function(message: string, details?: AlertDetails) {
      this.add(message, 'danger', details)
    },

    addSuccess: function(message: string) {
      // setTimeout(
      //   () => { ViewStore.alert.remove(message) },
      //   5 * 1000, // 5 secs
      // )
      this.add(message, 'success')
    },

    addWarning: function(message: string, details?: AlertDetails) {
      this.add(message, 'warning', details)
    },

    alerts: new Map(),

    alerts_array: [] as AlertMessageType[],

    getAlertsAsArray: function() {
      const array: AlertMessageType[] = []
      this.alerts.forEach(
        (value: AlertMessageType) => array.push(value)
      )
      return array
    },

    remove: function(message: string) {
      this.alerts.delete(message)
      this.alerts_array = this.getAlertsAsArray()
    },

    removeAll: function() {
      this.alerts.clear()
      this.alerts_array = this.getAlertsAsArray()
    },
  },

  progressbar: {
    _is_visibile: false,

    message: 'loading..',

    changeMessage: function(message: string) {
      this.message = message
    },

    hide: function() {
      this._is_visibile = false
      this.message = 'loading..'
    },

    show: function(message: string = 'loading..') {
      this._is_visibile = true
      this.message = message
    },

    visible: function(): boolean {
      return this._is_visibile
    },
  },
}


export default ViewStore

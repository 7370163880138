import ViewStore from '@/stores/view_store'
import { PaylinkReceiptType, UiInvoice } from '@/types/paylink_ui_types'
import { Helpers, maskPaymentMethod, MoneyFactory } from '@omnimerchant/helpers'
import { BusinesssTypes, Paylink } from '@omnimerchant/types'
import { paylinkInvoiceToUiInvoice } from './paylink_page_helpers'



export class PaylinkPageController {
  static current_controller : PaylinkPageController | undefined

  allowed_payment_methods : Paylink.Types.AllowedPaymentMethodTypes[] = []

  // TODO: @omnimerchant/types Paylinks.Types.PaylinkMini/Merchant
  // expose that we have gateway credentials in the
  // merchant type - this is great tip for potential
  // hackers, we are telling them where to look
  // may need a public safe @omnimerchant/types for this and
  // other reasons like IP protection of interfaces
  // (gateway, transactions, etc.)
  // OR have webpack/bundler delete all used code and types
  // on build
  paylink : Paylink.Paylinks.PublicPaylink | undefined

  paylink_client : Paylink.PublicClientTypes.PublicClientInterface

  payment_amount_by_invoice : Map<string, BusinesssTypes.Money> = new Map()

  payment_method : BusinesssTypes.ACHOrCard | undefined = undefined

  payment_methods_tracker = 0

  payment_receipt : any | undefined

  show_paylink_search_bar = true

  show_invoice_selection_section = false

  show_payment_section = false

  show_receipt = false

  surcharge = MoneyFactory.zero()

  surcharge_cache: Record<string,  BusinesssTypes.Money>= {}

  surcharge_tip: string = "*There will be a surcharge on credit cards that is not greater than our cost of acceptance. This surcharge is not applied on debit cards or ACH transactions."

  payment_applied_to_balance : BusinesssTypes.Money = MoneyFactory.createZero()

  receipts : PaylinkReceiptType[] = []

  unpaid_invoices : UiInvoice[] = []


  constructor(deps: {
    paylink_client: Paylink.PublicClientTypes.PublicClientInterface
  }) {
    this.paylink_client = deps.paylink_client
  }


  static getController(deps: {
    paylink_client: Paylink.PublicClientTypes.PublicClientInterface
  }) : PaylinkPageController {
    return new this(deps)
  }


  async loadPaylink(pl_id: string) {
    console.log('loadPaylink', pl_id)
    const pl = await this.paylink_client.get(pl_id)

    if (pl)
      console.log('loaded new pl', { paylink_id: pl_id })
    else
      console.warn('failed to load new pl', { paylink_id: pl_id })

    if (pl) this.load(pl)
  }


  async pay() {
    console.log('[pay]', this.paylink.id, this.payment_method?.type)

    try {
      ViewStore.progressbar.show('..paying')

      if (this.paylink == undefined)
        throw new Error('Paylink has not been loaded, aborting payment.')

      // TODO: update to support multiple payment methods
      const input : Paylink.UseCaseTypes.PayInput = {
        paylink_id: this.paylink.id,
        invoice_erp_id: this.selected_invoices[0].erp_id,
        amount: this.payment_applied_to_balance,
        payment_method: this.payment_method,
      }


      const pay_input = {
        invoice_ids: [input.invoice_erp_id],
        payment_methods_and_amounts: [
          {
            payment_method: input.payment_method,
            amount: input.amount,
          }
        ],
        paylink_id: input.paylink_id,
      }
      console.log('pay pl input', {
        amount: input.amount.display_amount_with_currency,
        invoices: pay_input.invoice_ids,
        pl: input.paylink_id,
      })
      // TODO: throw/reject when payment fails
      const resp = await this.paylink_client.pay(pay_input)

      this.receipts.length = 0
      for (const result of resp) {

        if (!result.payment_result.success) {
          ViewStore.alert.addDanger(
            // TODO: update to alert with Card or ACH info
            `Payment using ${result.payment_method.type} failed: ${result.payment_result.message}`)
          continue
        }

        const payment_result = result.payment_result as Paylink.UseCaseTypes.PaymentSuccess
        this.receipts.push({
          // ...this.receipt,
          amount_applied: payment_result.amount_applied,
          balance_remaining: MoneyFactory.sub(
            this.selected_invoices[0].total_due,
            payment_result.amount_applied,
          ),
          invoice_erp_id: this.selected_invoices[0].erp_id,
          fees: payment_result.fees,
          taxes: payment_result.taxes,
          total_amount_paid: payment_result.total_amount_paid,
          transaction_id: payment_result.transaction_id,
          message: payment_result.message,
          payment_method: maskPaymentMethod(result.payment_method),
          status: payment_result.status,
          success: true,
        })

      }

    } catch (error) {

      console.warn('pay pl error', Helpers.parseErrorMessage(error))

    } finally {

      console.log('pay pl finished')
      ViewStore.progressbar.hide()

    }
  }


  public get payment_breakdown(): { name: string; amount: string}[] {
    const breakdown = [
      {
        name: 'Surcharge',
        amount: this.surcharge.display_amount_with_currency,
      },
      // TODO: tax later
      // {
      //   name: 'Tax',
      //   amount: MoneyFactory.zero().display_amount_with_currency,
      // },
      {
        name: 'Payment',
        amount: this.payment_applied_to_balance.display_amount_with_currency,
      },
    ]
    return breakdown
  }


  get selected_invoices() {
    return this.unpaid_invoices.filter(i => i.selected)
  }


  get total_balance_due() {
    const max = this.unpaid_invoices.reduce((amount: number, inv: UiInvoice) => {
      return amount += inv.total_due.dollar_amount
    }, 0)
    return MoneyFactory.createFromDollar(max)
  }


  get total_payment() {
    return MoneyFactory.add(
      this.payment_applied_to_balance,
      this.surcharge,
    )
  }


  get total_min_payment() {
    const min = this.unpaid_invoices.reduce(
      (amount: number, inv: UiInvoice) => {
        return amount += inv.min_payment.dollar_amount
      }, 0,
    )
    return MoneyFactory.createFromDollar(min)
  }


  get selected_invoices_total_min_payment() {
    const min = this.selected_invoices.reduce(
      (amount: number, inv: UiInvoice) => {
        return amount += inv.min_payment.dollar_amount
      }, 0,
    )
    return MoneyFactory.createFromDollar(min)
  }


  handleAmountSelection(val: {
    invoice: UiInvoice, amount: BusinesssTypes.Money,
  }) {
    this.payment_amount_by_invoice.set(val.invoice.erp_id, val.amount)
    val.invoice.selected = true

    this.payment_applied_to_balance
      = this.payment_amount_by_invoice.get(
        this.selected_invoices[0].erp_id
      ) ?? this.selected_invoices[0].min_payment
  }


  handleInvoiceSelection(inv: UiInvoice) {
    console.log('[handleInvoiceSelection]', inv)
    if (inv.selected) {
      this.payment_applied_to_balance = inv.min_payment
    } else {
      this.payment_applied_to_balance = MoneyFactory.createZero()
    }
  }


  hasPaylink() {
    return this.paylink != undefined
  }


  hasReceipt() {
    return this.receipts.length > 0
  }


  hasUnpaidInvoices() {
    return this.unpaid_invoices.length > 0
  }


  isInvoiceSelected() {
    return this.selected_invoices.length > 0
  }


  isInvoiceVisible(invoice: UiInvoice) {
    if (invoice.selected) return false
    // disables all invoices that were not selected
    // if an invoice was selected
    return this.selected_invoices.length > 0
  }


  isReadyToPay() {
    // TODO: check that the ACH and card are valid -
    // the required on the inputs does prevent the form
    // submission
    return this.isInvoiceSelected()
      && this.payment_method
  }


  load(paylink: Paylink.Paylinks.PublicPaylink) {
    console.log('[load] new paylink', paylink)
    this.paylink = {
      ...this.paylink,
      ...paylink,
    }
    this.unpaid_invoices = paylink.invoices
      .filter(inv => {
        return inv.balance_remaining != 0
      }).map(
        paylinkInvoiceToUiInvoice
      )
    if (this.unpaid_invoices.length > 0)
      this.allowed_payment_methods = this.unpaid_invoices[0].allowed_payment_methods
  }


  // TODO: test me
  reset() {
    this.payment_breakdown[0].amount = MoneyFactory
      .zero().display_amount_with_currency
    this.paylink = undefined
    this.payment_method = undefined
    this.payment_receipt = undefined
    this.show_invoice_selection_section = false
    this.show_paylink_search_bar = false
    this.show_payment_section = false
    this.show_receipt = false
    this.surcharge = MoneyFactory.zero()
    this.surcharge_cache = {}
    this.payment_applied_to_balance = MoneyFactory.createZero()
    this.unpaid_invoices.length = 0
  }


  // TODO: create string template literal type that
  // enforces string length and content
  async calculateSurcharge(payment_method: BusinesssTypes.ACHOrCard) {
    try {
      console.log('[ctrl.calculateSurcharge]')

      // we don't surcharge ACH
      if (payment_method.type == 'ACH') {
        console.log('[ctrl.calculateSurcharge] ACH, skipping surcharge')
        return
      }

      // TODO: change this when we surcharge individual invoices
      if (this.paylink.invoices[0].surcharge == false) {
        console.log('[ctrl.calculateSurcharge] surcharge not enabled, skipping surcharge')
        return
      }

      if (payment_method.number.length < 6)  {
        console.log('[ctrl.calculateSurcharge] no CC bin entered, skipping surcharge')
        return
      }

      const last_cc_bin = payment_method.number.slice(0, 6)
      const cache_key = this.createSurchargeCacheKey(last_cc_bin)
      const prior = this.surcharge_cache[cache_key]
      if (prior) {
        this.payment_breakdown[0].amount = prior.display_amount_with_currency
        console.log('[ctrl.calculateSurcharge] using cache, skipping surcharge', prior.display_amount_with_currency)
        return
      }

      console.log('[ctrl.calculateSurcharge]', {
        amount: this.payment_applied_to_balance.display_amount_with_currency,
        cc_bin: last_cc_bin,
        paylink_url_id: this.paylink.id,
      })
      const output = await this.paylink_client.calculateSurcharge({
        amount: this.payment_applied_to_balance,
        cc_bin: last_cc_bin,
        paylink_url_id: this.paylink.id,
      })

      console.log('[ctrl.calculateSurcharge] surcharge:', output)

      this.surcharge = output.amount
      this.surcharge_cache[cache_key] = output.amount
      this.payment_breakdown[0].amount = output.amount.display_amount_with_currency

    } catch (error) {

      console.warn('[ctrl.calculateSurcharge] error', error)

    }
  }


  protected createSurchargeCacheKey(last_cc_bin: string) {
    return last_cc_bin + this.payment_applied_to_balance.display_amount
  }


  protected static createDummyInvoice() {
    return {
      erp_id: Math.random().toString(),
      reference_number: '#123',
      due_date: new Date(),
      memo: 'memo',
      items: [
        { erp_id: 'erp_id1', name: 'item', description: 'description', quantity: 10, unit_price: MoneyFactory.createFromDollar(10.87), total: MoneyFactory.createFromDollar(108.70) },
      ],
      balance_due: MoneyFactory.createFromDollar(1020.34),
      total_due: MoneyFactory.createFromDollar(4231.98),
      min_payment: MoneyFactory.createFromDollar(100),
      selected: false,
    }
  }


  protected static createDummyPaylink() : Paylink.Paylinks.PublicPaylink {
    return {
      id: 'paylink id',
      customer: {
        full_name: 'Albert Holmes',
        erp_id: 'cstmr erp id',
        billing_address: {
          name: 'Albert Holmes',
          street_address1: '1200 Forest',
          street_address2: 'Suite 2',
          city: 'Redwood',
          state: 'CA',
          zip: '94612',
        },
      },
      merchant_contact_info: {
        name: 'Merchant Name',
        email: 'email@address.com',
        phone: '(415)555 - 8889',
      },
      invoices: [],
      is_active: true,
    }
  }
}


// export type UiReceipt = {
//   amount_applied: BusinesssTypes.Money
//   balance_remaining: BusinesssTypes.Money
//   fees: BusinesssTypes.Money
//   taxes: BusinesssTypes.Money
//   total_amount_paid: BusinesssTypes.Money
//   transaction_id: string
//   message: string,
//   status: Paylink.UseCaseTypes.PaymentStatus
//   success: boolean
// }

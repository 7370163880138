import {
  BootstrapVue,
  BootstrapVueIcons,
  IconsPlugin,
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../styles/vue_bootstrap.scss'


export function setupBoostrap(Vue: { use: (any) => void}) {
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  Vue.use(IconsPlugin)
}

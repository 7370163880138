import Vue from 'vue'
import App from './App.vue'
import { setupBoostrap } from './bootstrap/bootstrap_vue'
import { Errors } from '@omnimerchant/helpers'


// TODO: setup paylink use cases and pass into dependencies
// or Vue?
export function init() {
  console.log('[init] starting site')

  // so that test errors are sent to to the test runner instead
  // of being swallowed by the ErrorService
  // [vue-test-utils]: Global error handler detected (Vue.config.errorHandler).
  // Vue Test Utils sets a custom error handler to throw errors thrown by instances.
  // If you want this behavior in your tests, you must remove the global error handler.
  if (process.env.VUE_APP_NODE_ENV === 'production' ||
    process.env.VUE_APP_NODE_ENV === 'staging') {
    Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
      Vue.prototype.$error_service.error(err)
    }

    const error_service = Errors.makeErrorService({
      code_version: '1.0.0',
      // TODO: redfine env type to long form ie. production, staging,..
      // isntead of abbreviations - be consistent
      env: process.env.VUE_APP_NODE_ENV === 'production' ? 'prod' : 'dev',
      logrocket_project_name: process.env.VUE_APP_LOGROCKET_PROJECT_NAME,
    })
    Vue.prototype.$error_service = error_service

  } else {

    const error_service = {
      error: (e: unknown) => console.warn('[ErrorService]', e)
    }
    Vue.prototype.$error_service = error_service

  }

  // Import Bootstrap an BootstrapVue CSS files (order is important)
  setupBoostrap(Vue)
  Vue.config.productionTip = false

  console.log('[init] setup finished')

  new Vue({
    render: h => h(App),
  }).$mount('#app')

  console.log('[init] app mounted')
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-container",
        {
          staticClass:
            "blue-shadow d-flex justify-content-center align-items-center bg-white rounded text-start",
        },
        [
          _c("b-row", { staticClass: "px-4 pt-4 pb-4" }, [
            _c("p", [
              _vm._v(" Email : "),
              _c(
                "a",
                { attrs: { href: "mailto: appsupport@omnimerchant.com" } },
                [_vm._v("appsupport@omnimerchant.com")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
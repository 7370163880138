var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.ViewStore.progressbar.visible(),
          expression: "ViewStore.progressbar.visible()",
        },
      ],
      staticClass:
        "progressbar row h-100 justify-content-center align-items-center",
    },
    [
      _c("b-col", [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("span", {
            staticClass: "spinner-border",
            attrs: { role: "status" },
          }),
        ]),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("p", { staticClass: "text-center", attrs: { role: "alert" } }, [
            _vm._v(" " + _vm._s(_vm.ViewStore.progressbar.message) + " "),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
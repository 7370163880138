var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row align-items-center p-3" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            width: "50px",
            src: require("../assets/logo.png"),
            alt: "OmniMerchant Logo",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
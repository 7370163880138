var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-input", {
    staticClass: "dollar-amount",
    attrs: {
      value: _vm.value,
      required: _vm.required,
      type: "number",
      step: "0.01",
      min: _vm.min,
      max: _vm.max,
    },
    on: { keydown: _vm.onlyAllowNumericInput, input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { makeClient } from '@omnimerchant/paylink-public-client'
import { Paylink } from '@omnimerchant/types'



function getApiUrl() {
  return process.env.VUE_APP_API_URL
}


export class DependencyContainer {
  protected static paylink_client: Paylink.PublicClientTypes.PublicClientInterface

  protected static initialized = false


  static get() {
    console.log('[DependencyContainer.get]', DependencyContainer.initialized)
    if (!DependencyContainer.initialized) DependencyContainer.init()

    return {
      paylink_client: DependencyContainer.paylink_client
    }
  }


  static set(
    options: { paylink_client: Paylink.PublicClientTypes.PublicClientInterface }
  ) {
    DependencyContainer.paylink_client = options.paylink_client
    DependencyContainer.initialized = true
  }


  static init(options?: { api_url?: string }) {
    let api_url = getApiUrl()
    if (options && options.api_url) api_url = options.api_url

    DependencyContainer.paylink_client = makeClient({ api_url })
    DependencyContainer.initialized = true
  }
}

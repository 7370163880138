import { AnalyticsProviders, TrackingInfo, SegmentTrackingTypes } from "@/types/types"

export function track(trackingInfo: TrackingInfo) {

  try {

    segment_tracking()

    switch (trackingInfo.tracking_type) {

      case SegmentTrackingTypes.Track:
        segment_track(trackingInfo.message, trackingInfo.properties)
        break
      case SegmentTrackingTypes.Page:
        segment_page_track(trackingInfo.message)
        break
      default : console.error("Invalid tracking type used" , trackingInfo.tracking_type)
    }
  } catch (error) {
    console.log("Tracking Service Failed")
    console.log(error)
  }
}

function segment_track(message: string, properties: any | undefined) {

  // @ts-expect-error window is imported from segment, which is JS
  window.analytics.track(message, {
    properties
  })

}

function segment_page_track(message: string) {

  // @ts-expect-error window is imported from segment, which is JS
  window.analytics.page(message)

}

/* eslint-disable */
function segment_tracking() {

  // @ts-expect-error
  !function () {
     // @ts-expect-error
    const analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
      // @ts-ignore
      analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { const e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (let t = 0; t < analytics.methods.length; t++) { const e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { const n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; const a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
      analytics.load("nFFyDZQ4KDMz4OJ4PqCwKTbxnZsVirfA");
      analytics.page() // Uncomment if your application is NOT an SPA
    }
  }();
}
  /* eslint-enable */

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-container",
        {
          staticClass:
            "blue-shadow d-flex justify-content-center align-items-center bg-white rounded text-start",
        },
        [
          _c(
            "b-row",
            { staticClass: "px-4 pt-4 pb-4" },
            [
              _c("b-col", { attrs: { id: "about-info" } }, [
                _c("p", [
                  _vm._v(
                    " OmniMerchant is a US-based payments system that allows merchants and their customers to easily pay invoices and reconcile their books. For additional information and assitance contact "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "mailto: appsupport@omnimerchant.com" } },
                    [_vm._v("appsupport@omnimerchant.com")]
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " If you are a merchant insterested in using OmniMerchant, check out our merchant facing "
                  ),
                  _c("a", { attrs: { href: "https://omnimerchant.com/" } }, [
                    _vm._v("website."),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import ViewStore, { AlertMessageType, AlertDetails } from '../stores/view_store'
import Vue from 'vue'

type UiAlert =  AlertMessageType & {
  id: string,
  show_details: boolean,
}



export default Vue.extend({
  name: 'Alerts',


  data: function() {
    return {
      ViewStore,
      show_details_map: {},
      show_error_text: false,
    }
  },


  computed: {
    alerts: () => {
      let id = 0
      // TODO: add multiple duplicates, Main Bar -> Reconnect to GW
      return ViewStore.alert.alerts_array.map(
        alert => {
          id += 1
          return {
            ...alert,
            id: id.toString(),
            show_details: false,
          }
        }
      )
    },
  },


  methods: {
    dismissAlert: function(alert_message: string) {
      ViewStore.alert.remove(alert_message)
    },

    hasDetails: function(alert: AlertMessageType) {
      return !!alert.details
    },

    printDetails: function(alert_details?: AlertDetails) {
      if (!alert_details)
        return 'no details available'
      else if (alert_details instanceof Error)
        return alert_details.toString()
      else
        return JSON.stringify(alert_details)
    },

    toggleDetails: function(alert: UiAlert) {
      alert.show_details = !alert.show_details
      // this forces Vue to show/hide the details
      // by revaluating alert.show_details which
      // can't be a computed property because it is
      // inside a for loop
      this.$forceUpdate()
    },
  }
})

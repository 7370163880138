var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-row",
        { staticClass: "w-100" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "8", lg: "6" } },
            [
              _c("b-table", {
                staticClass: "text-align-left",
                attrs: {
                  id: "amounts-table",
                  items: _vm.receipt_amounts,
                  "thead-class": "d-none",
                },
              }),
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", sm: "4", lg: "6" } }, [
            _c("img", {
              staticClass: "img-fluid w-100",
              attrs: { src: require("../assets/campsite.jpg") },
            }),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "w-100" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("h3", { staticClass: "text-align-left pl-2" }, [
                _vm._v(" Payments "),
              ]),
              _c("b-table", {
                attrs: {
                  id: "payments-table",
                  items: _vm.payment_details,
                  responsive: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
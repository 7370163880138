
import Vue from 'vue'
import ViewStore from '../stores/view_store'



export default Vue.extend({
  name: 'ProgressBar',


  props: {},


  data: function() {
    return {
      ViewStore
    }
  },
})

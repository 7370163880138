
import { track } from "@/services/TrackingService"
import {
  SegmentTrackingTypes,
  TrackingInfo
} from "@/types/types"
import Vue from "vue"
import { Component } from "vue-property-decorator"

@Component
export default class AboutPage extends Vue {
  mounted(): void {
    const trackingInfo: TrackingInfo = {
      tracking_type: SegmentTrackingTypes.Page,
      message: "About Page",
      properties: undefined,
    }
    track(trackingInfo)
  }
}

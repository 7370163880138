import * as UiTypes from "./ui_types"



export function createEmptyAch(): UiTypes.ACH {
  return {
    account_name: 'Checking',
    account_number: '',
    account_type: UiTypes.AchAccountTypeValues.CHECKING,
    routing_number: '',
    type: 'ACH',
  }
}


export function createEmptyAddress(): UiTypes.BillingAddress {
  return {
    name: '',
    street_address1: '',
    street_address2: '',
    city: '',
    state: '',
    zip: '0',
  }
}


export function createEmptyCreditCard(): UiTypes.CreditCard {
  return {
    number: '',
    expiration_month_one_indexed: 6,
    expiration_year_two_digit: (new Date().getFullYear() % 100) + 1,
    security_code: '',
    type: 'Card',
  }
}


export function createEmptyVaultPayment(): UiTypes.VaultPayment {
  return {
    customer_id: '',
    payment_method_id: '',
    payment_method_type: 'Card',
    type: 'Vault',
  }
}

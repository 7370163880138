
import { MoneyFactory } from '@omnimerchant/helpers'
import Vue from 'vue'



const FULL_PAYMENT = 'full payment'
const PARTIAL_PAYMENT = 'partial payment'
export default Vue.extend({
  name: 'PaylinkInvoice',


  components: {},


  props: {
    invoice: {
      default: () => {
        return {
          reference_number: '#123',
          due_date: new Date(),
          memo: 'memo',
          line_items: [
            { erp_id: 'erp_id1', name: 'item', description: 'description', quantity: 10, amount: MoneyFactory.createFromDollar(108.70) },
          ],
          balance_due: MoneyFactory.createFromDollar(1020.34),
          total_due: MoneyFactory.createFromDollar(4231.98),
          min_payment: MoneyFactory.createFromDollar(100),
        }
      },
      type: Object,
    },

    show_line_items_prop: { default: () => false, type: Boolean },

    value: {
      default: () => { return 0 },
      type: Number,
      validator: (val : Number) => {
        return typeof(val) == 'number'
      }
    },
  },


  data: function() {
    return {
      amount_being_paid: this.invoice.min_payment.dollar_amount,
      payment_type: FULL_PAYMENT,
      show_line_items: false,
      FULL_PAYMENT,
      PARTIAL_PAYMENT,
    }
  },


  computed: {
    due_date: {
      get() {
        if (this.invoice.due_date)
          return this.invoice.due_date.toDateString()
        else
          return 'NA'
      }
    }
  },


  mounted: function() {
  },


  methods: {
    emitFullBalance() {
      this.$emit('input', {
        invoice: this.invoice,
        amount: this.invoice.total_due,
      })
    },


    emitPartialBalance() {
      let amount = parseFloat(this.amount_being_paid)
      if (Number.isNaN(amount) || this.amount_being_paid == '')
        amount = 0
      this.$emit('input', {
        invoice: this.invoice,
        amount: MoneyFactory.createFromDollar(amount),
      })
    },


    handlePayAmountDue() {
      console.log('handlePayAmountDue')
      this.amount_being_paid = this.invoice.min_payment.dollar_amount
      this.emitPartialBalance()
    },


    handlePayFullBalance() {
      console.log('handlePayFullBalance')
      this.amount_being_paid = this.invoice.total_due.dollar_amount
      this.emitFullBalance()
    },


    // vue returns value from number inputs as string, idk why
    handlePaymentAmountInput(value: string) {
      console.log('handlePaymentAmountInput', this.amount_being_paid, value)
      if (this.payment_type != PARTIAL_PAYMENT) this.payment_type = PARTIAL_PAYMENT
      this.emitPartialBalance()
    },
  },
})

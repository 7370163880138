
import * as config from '../../config'
import Vue from 'vue'
import * as UiTypes from './ui_types'
import { createEmptyCreditCard } from './placeholders'
import { CardInputController } from './card_input_controller'
import { BusinesssTypes } from '@omnimerchant/types'



export default Vue.extend({
  name: 'CardIndput',


  props: {
    formReset: {
      default: () => { return 0 },
      type: Number,
    },
    getSecurityCode: {
      default: () => { return true },
      type: Boolean,
    },
    value: {
      default: () => {
        return createEmptyCreditCard()
      },
      type: Object,
    },
  },


  // emits: ['input'],


  data: function() {
    return {
      ctrl: new CardInputController(),
      // TODO: migrate this into the controller
      credit_card: {
        number: this.$props.value.number,
        expiration_month_one_indexed: this.$props.value.expiration_month_one_indexed,
        expiration_year_two_digit: this.$props.value.expiration_year_two_digit,
        security_code:  this.$props.value.securityCode,
        type: BusinesssTypes.PaymentMethodTypeValues.Card,
      } as UiTypes.CreditCard,
      expiration_date_mm_has_two_digits: false,
      is_dev: config.is_dev,
      minimum_year: new Date().getFullYear() % 100,
    }
  },


  computed: {
    formatedExpirationDate: {
      get: function() {
        let formated = ''

        if (this.credit_card.expiration_month_one_indexed > 0 && this.credit_card.expiration_year_two_digit > 0)
          formated = `${this.credit_card.expiration_month_one_indexed}/${this.credit_card.expiration_year_two_digit}`
        else if (this.credit_card.expiration_month_one_indexed > 1 || this.expiration_date_mm_has_two_digits)
          formated = `${this.credit_card.expiration_month_one_indexed}/`
        else if (this.credit_card.expiration_month_one_indexed <= 1)
          formated = `${this.credit_card.expiration_month_one_indexed}`
        else if (this.credit_card.expiration_year_two_digit > 0)
          formated = `/${this.credit_card.expiration_year_two_digit}`
        else
          formated = ''

        return formated
      },
      set: function(value: string) {
        this.setExprationDate(value)
      },
    }
  },


  watch: {
    // watchs the formReset prop and clears the form
    // when it changes
    formReset: function() {
      this.ctrl.clearCardData()
      this.credit_card.security_code = ''
      this.credit_card.expiration_month_one_indexed = 6
      this.credit_card.expiration_year_two_digit = 30
    },
  },


  methods: {
    fill: function() {
      this.ctrl.masked_card_number = '4012 0000 9876 5439'
      this.credit_card.number = '4012000098765439'
      this.credit_card.expiration_month_one_indexed = 12
      this.credit_card.expiration_year_two_digit = 30
      this.credit_card.security_code = '999'
      this.fireInputEvent()
    },

    fireCreditCardInputEvent: function() {
      this.ctrl.updateMaskedCardNumber()
      this.credit_card.number = this.ctrl.getCardNumber()
      this.$emit(
        'input',
        this.credit_card as UiTypes.CreditCard,
      )
    },

    fireInputEvent: function() {
      this.$emit(
        'input',
        this.credit_card as UiTypes.CreditCard,
      )
    },

    handleExpirationDateInput: function() {
      this.fireInputEvent()
    },

    setExprationDate: function(combined: string) {
      // remove all non-digit and '/' characters
      const dates = combined.replace(/[^\d//]/g,'').split('/')
      if (dates[0] != undefined && dates[0] != '') {
        this.credit_card.expiration_month_one_indexed = parseInt(dates[0])
        this.expiration_date_mm_has_two_digits = dates[0].length == 2
      } else
        this.credit_card.expiration_month_one_indexed = NaN

      if (dates[1] != undefined && dates[1] != '') {
        this.credit_card.expiration_year_two_digit = parseInt(dates[1])
      } else
        this.credit_card.expiration_year_two_digit = NaN
    },
  },
})

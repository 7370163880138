var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "customer-info" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "text-align-center", attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "info" }, [_vm._v(" Balance ")]),
              _c("h2", [
                _vm._v(_vm._s(_vm.totalDue.display_amount_with_currency)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "mb-3", attrs: { cols: "12", sm: "3" } }, [
            _c("p", { staticClass: "info inline" }, [_vm._v(" From: ")]),
            _c("h5", { staticClass: "inline" }, [
              _vm._v(" " + _vm._s(_vm.merchantContactInfo.name) + " "),
            ]),
          ]),
          _c(
            "b-col",
            {
              staticClass: "text-align-left mb-3",
              attrs: {
                id: "merchant-contact-info",
                "data-private": "",
                cols: "12",
                sm: "3",
              },
            },
            [
              _c("h6", [_vm._v(_vm._s(_vm.merchantContactInfo.email))]),
              _c("h6", [_vm._v(_vm._s(_vm.merchantContactInfo.phone))]),
            ]
          ),
          _c("b-col", { staticClass: "mb-3", attrs: { cols: "12", sm: "3" } }, [
            _c("p", { staticClass: "info inline" }, [_vm._v(" To: ")]),
            _c("h5", { staticClass: "inline" }, [
              _vm._v(" " + _vm._s(_vm.name) + " "),
            ]),
          ]),
          _c(
            "b-col",
            {
              staticClass: "text-align-left mb-3",
              attrs: {
                id: "customer-address",
                "data-private": "",
                cols: "12",
                sm: "3",
              },
            },
            [
              _c("h6", [_vm._v(_vm._s(_vm.address.street_address1))]),
              _c("h6", [_vm._v(_vm._s(_vm.address.street_address2))]),
              _c("h6", [
                _vm._v(
                  _vm._s(_vm.address.state) +
                    ", " +
                    _vm._s(_vm.address.city) +
                    " " +
                    _vm._s(_vm.address.zip)
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import {
  onlyAllowNumericInput,
  removeNonDigits,
} from './ui_helpers'
import { MoneyFactory } from '@omnimerchant/helpers'


// WARNING DOES NOT HANDLE NUMBERS WITH DECIMAL POINTS
// todo: fix later
export default Vue.extend({
  name: 'DollarInput',


  props: {
    min: {
      default: () => { return 0.00 },
      type: Number,
    },
    max: {
      default: () => { return 0.00 },
      type: Number,
    },
    required: {
      default: () => { return true },
      type: Boolean,
    },
    step: {
      default: () => { return 0.01 },
      type: Number,
    },
    value: {
      default: () => {
        return '0.00'
      },
      type: String,
    },
  },


  // emits: ['input'],


  methods: {
    handleInput: function(value : string) {
      const numbers = removeNonDigits(value, true)
      this.$emit(
        'input',
        // throws an error, not a number
        // MoneyFactory.createFromDollar(parseFloat(numbers)),
        parseFloat(numbers),
      )
    },

    onlyAllowNumericInput: function(event : KeyboardEvent) {
      onlyAllowNumericInput(event, true)
    },
  },
})

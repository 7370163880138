import { UiInvoice } from "@/types/paylink_ui_types"
import { MoneyFactory } from "@omnimerchant/helpers"
import { BusinesssTypes, ERP, Paylink } from "@omnimerchant/types"



// TODO: test me
export function calculateMinPayment(invoice: Paylink.UseCaseTypes.PublicInvoice) : BusinesssTypes.Money {
  return MoneyFactory.createFromDollar(
    invoice.balance_remaining * invoice.minimum_payment.as_percent_decimal
  )
}


export function createPaylinkInput() : Paylink.UseCaseTypes.CreatePaylinkInput {
  const invoice_dict : Paylink.Types.InvoiceDict = {}
  const allowed_payment_methods = [
    // BusinesssTypes.PaymentMethodTypeValues.ACH,
    // BusinesssTypes.PaymentMethodTypeValues.Card, //includes vault, cant use
    'ACH', 'Card'
  ] as Paylink.Types.AllowedPaymentMethodTypes[]
  const dummy_invoices = {
    '0': {
      erp_id: '0',
      balance_remaining: 322.15,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: false,
      reference_number: 'JJsXPanshw',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    },
    '1': {
      erp_id: '1',
      balance_remaining: 5062.92,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: false,
      reference_number: 'vPeEHhtHep',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    },
    '2': {
      erp_id: '2',
      balance_remaining: 7905.91,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: true,
      reference_number: 'IVMVlDbzBQ',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    },
    '3': {
      erp_id: '3',
      balance_remaining: 0,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: false,
      reference_number: 'IVMVlDbzBQ',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    }
  }
  invoice_dict[dummy_invoices['0'].erp_id] = dummy_invoices['0']
  invoice_dict[dummy_invoices['1'].erp_id] = dummy_invoices['1']
  invoice_dict[dummy_invoices['2'].erp_id] = dummy_invoices['2']
  invoice_dict[dummy_invoices['3'].erp_id] = dummy_invoices['3']
  return {
    erp_deposit_account_id: 'erp deposit acount',
    customer: {
      billing_address: {
        name: 'Ray Ryan',
        street_address1: 'Ezipo Terrace',
        street_address2: 'Apt Addr2',
        city: 'Dilejmob',
        state: 'WY',
        zip: '88984'
      },
      full_name: 'Raytheon',
      erp_id: 'ObkIyQhFph'
    },
    merchant: {
      contact_info: {
        name: 'Merchant Name',
        email: 'email@address.com',
        phone: '(415)555 - 8889'
      },
      gateway: 'FLUIDPAY',
      gateway_creds: {
        api_key: 'api',
      },
      id: 'QnHcngpoNK'
    },
    invoices: [dummy_invoices['0'], dummy_invoices['1']],
  }
}


export function createPaidPaylinkInput() : Paylink.UseCaseTypes.CreatePaylinkInput {
  const invoice_dict : Paylink.Types.InvoiceDict = {}
  const allowed_payment_methods = [
    // BusinesssTypes.PaymentMethodTypeValues.ACH,
    // BusinesssTypes.PaymentMethodTypeValues.Card, //includes vault, cant use
    'ACH', 'Card'
  ] as Paylink.Types.AllowedPaymentMethodTypes[]
  const dummy_invoices = {
    '0': {
      erp_id: '0',
      balance_remaining: 0,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: true,
      reference_number: 'JJsXPanshw',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    },
    '1': {
      erp_id: '1',
      balance_remaining: 0,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: true,
      reference_number: 'vPeEHhtHep',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    },
    '2': {
      erp_id: '2',
      balance_remaining: 0,
      customer_full_name: 'customer name',
      customer_erp_id: 'ObkIyQhFph',
      due_date: new Date(),
      is_deleted: false,
      is_paid: true,
      reference_number: 'IVMVlDbzBQ',
      allowed_payment_methods,
      line_items: [],
      payments: [],
      minimum_payment: { as_percent_decimal: 0.1 }
    }
  }
  invoice_dict[dummy_invoices['0'].erp_id] = dummy_invoices['0']
  invoice_dict[dummy_invoices['1'].erp_id] = dummy_invoices['1']
  invoice_dict[dummy_invoices['2'].erp_id] = dummy_invoices['2']
  return {
    erp_deposit_account_id: 'erp deposit acount',
    customer: {
      billing_address: {
        name: 'Ray Ryan',
        street_address1: 'Ezipo Terrace',
        street_address2: 'Apt Addr2',
        city: 'Dilejmob',
        state: 'WY',
        zip: '88984'
      },
      full_name: 'Raytheon',
      erp_id: 'ObkIyQhFph'
    },
    merchant: {
      contact_info: {
        name: 'Merchant Name',
        email: 'email@address.com',
        phone: '(415)555 - 8889'
      },
      gateway: 'FLUIDPAY',
      gateway_creds: {
        api_key: 'api',
      },
      id: 'QnHcngpoNK'
    },
    invoices: [dummy_invoices['0'], dummy_invoices['1']],
  }
}


export function toPaymentMethodString(
  pm: Paylink.Types.AllowedPaymentMethods
) : string {
  if (pm.type == 'ACH')
    return `Account ****${pm.account_number.slice(-4)}`
  else
    return `Card ****${pm.number.slice(-4)}`
}


// TODO: create separate (from controntroller) test
export function paylinkInvoiceToUiInvoice(inv: Paylink.UseCaseTypes.PublicInvoice): UiInvoice {
  const min_payment = inv.balance_remaining * inv.minimum_payment.as_percent_decimal
  return {
    allowed_payment_methods: inv.allowed_payment_methods,
    erp_id: inv.erp_id,
    reference_number: inv.reference_number,
    due_date: inv.due_date,
    memo: '',
    line_items: inv.line_items,
    total_due: MoneyFactory.createFromDollar(inv.balance_remaining),
    min_payment: MoneyFactory.createFromDollar(min_payment),
    selected: false,
    surchargeable: inv.surcharge,
  }
}

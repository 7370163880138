
import Vue from "vue"
import { Component, Prop, ModelSync } from "vue-property-decorator"
import AchInput from "../components/omni/AchInput.vue"
import CardInput from "../components/omni/CardInput.vue"
import DollarInput from "../components/omni/DollarInput.vue"
import { BusinesssTypes, Paylink } from '@omnimerchant/types'
import { PaylinkPageController } from '@/pages/paylink/paylink_page_controller'
import * as UiTypes from './omni/ui_types'




@Component({
  components: {
  AchInput,
  CardInput,
  DollarInput,
  },
  })
export default class PaylinkPaymentMethods extends Vue {
  @Prop() readonly allowed_payment_methods: ['ACH', 'Card']

  @Prop() readonly max_payment: BusinesssTypes.Money

  @Prop() readonly min_payment: BusinesssTypes.Money

  @Prop() readonly surchargeable: boolean = false

  // TODO: replace with interface, PaylinkControllerInterface
  @Prop() readonly ctrl: PaylinkPageController

  @ModelSync('payment_method', 'input')
  payment_method_value: UiTypes.ACH | UiTypes.CreditCard = {
    number: '',
    expiration_month_one_indexed: 6,
    expiration_year_two_digit: 25,
    security_code: '',
    type: 'Card'
  }

  ach: UiTypes.ACH = {
    account_name: '',
    account_number: '',
    account_type: 'CHECKING',
    routing_number: '',
    type: 'ACH',
  }

  card: UiTypes.CreditCard = {
    number: '',
    expiration_month_one_indexed: 6,
    expiration_year_two_digit: 25,
    security_code: '',
    type: 'Card'
  }

  payment_method_type : Paylink.Types.AllowedPaymentMethodTypes = 'ACH'

  payment_methods_tracker = 0


  async mounted(): Promise<void> {
    console.log("PaylinkPaymentMethods::mounted")
  }


  // TODO: for multiple payment methods
  // spreadTotalPaymentsAcrossAllPaymentMethods() {
  //   const lowest = this.payment_amount.amount_in_lowest_denomination
  //   const pms = this.payment_method_value.size
  //   const each = Math.floor(lowest / pms)
  //   console.log('[spreadTotal]', { lowest, pms, each })
  //   this.payment_method_value.forEach(
  //     (value: { amount: BusinesssTypes.Money }, key: number) => {
  //       value.amount = MoneyFactory.createFromPennies(each)
  //       this.payment_method_value.set(key, value)
  //     }
  //   )
  // }


  forceVueToUpdate() {
    this.payment_methods_tracker += 1
  }


  get payment_breakdown() {
    // TODO: test me, no surcharge when not surchargeable
    if (this.isAchPayment() || !this.surchargeable)
      return this.ctrl.payment_breakdown.filter(p => p.name != 'Surcharge')
    else
      return this.ctrl.payment_breakdown
  }


  hasAchPaymentMethod() {
    return this.payment_method_value.type == 'ACH'
  }


  hasCardPaymentMethod() {
    return this.payment_method_value.type == 'Card'
  }


  areAchPaymentsAllowed() {
    return this.allowed_payment_methods.includes('ACH')
  }


  areCardPaymentsAllowed() {
    return this.allowed_payment_methods.includes('Card')
  }


  emitPaymentMethod(payment_method: UiTypes.ACH | UiTypes.CreditCard) {
    // @ts-ignore
    this.$emit(
      'input', payment_method
    )
  }


  showAchForm() {
    this.payment_method_type = 'ACH'
    this.emitPaymentMethod(this.ach)
  }


  isAchPayment() {
    return this.payment_method_type == 'ACH'
  }


  showCardForm() {
    this.payment_method_type = 'Card'
    this.emitPaymentMethod(this.card)
  }


  isCardPayment() {
    return this.payment_method_type == 'Card'
  }


  updateAch(ach: UiTypes.ACH) {
    this.ach = ach
    this.payment_method_value = ach
    this.emitPaymentMethod(ach)
  }


  updateCard(card: UiTypes.CreditCard) {
    this.card = card
    this.payment_method_value = card
    this.emitPaymentMethod(card)
  }
}

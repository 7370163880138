var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center", attrs: { id: "alert-wrapper" } },
    _vm._l(_vm.alerts, function (alert) {
      return _c(
        "b-alert",
        {
          key: alert.message,
          staticClass: "alert-message",
          attrs: {
            id: `alert-${alert.id}`,
            variant: alert.type,
            show: "",
            dismissible: "",
          },
          on: {
            dismissed: function ($event) {
              return _vm.dismissAlert(alert.message)
            },
          },
        },
        [
          _vm.hasDetails(alert)
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "11" } }, [
                    _vm._v(" " + _vm._s(alert.message) + " "),
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "1" } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            id: `details-btn-${alert.id}`,
                            size: "sm",
                            variant: "outline-dark",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleDetails(alert)
                            },
                          },
                        },
                        [_vm._v(" Details ")]
                      ),
                    ],
                    1
                  ),
                  alert.show_details ? _c("hr") : _vm._e(),
                  alert.show_details
                    ? _c("p", { staticClass: "error-text mb-0" }, [
                        _vm._v(
                          " " + _vm._s(_vm.printDetails(alert.details)) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.hasDetails(alert)
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "11" } }, [
                    _vm._v(" " + _vm._s(alert.message) + " "),
                  ]),
                  _c("b-col", { attrs: { sm: "1" } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    {
      staticClass: "app-container h-100",
      attrs: { id: "app-container", fluid: "lg" },
    },
    [
      _c("Alerts"),
      _c("ProgressBar"),
      _c("MerchantHeader", {
        attrs: { id: "logo" },
        on: { click: _vm.showAllTabs },
      }),
      _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c(
            "b-nav",
            { attrs: { justified: "" } },
            [
              _c(
                "b-nav-item",
                { attrs: { active: _vm.$route.name == "paylink" } },
                [
                  _c(
                    "router-link",
                    { staticClass: "w-100 block", attrs: { to: "/paylink" } },
                    [_vm._v(" Pay Paylink ")]
                  ),
                ],
                1
              ),
              _c(
                "b-nav-item",
                { attrs: { active: _vm.$route.name == "about" } },
                [
                  _c(
                    "router-link",
                    { staticClass: "w-100 block", attrs: { to: "/about" } },
                    [_vm._v(" About ")]
                  ),
                ],
                1
              ),
              _c(
                "b-nav-item",
                { attrs: { active: _vm.$route.name == "contact" } },
                [
                  _c(
                    "router-link",
                    { staticClass: "w-100 block", attrs: { to: "/contact" } },
                    [_vm._v(" Contact ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("router-view", {
            staticClass: "mt-4",
            on: { "remove-create-edit-tabs": _vm.hideCreateEditTabs },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
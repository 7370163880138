// import ItemType from './item_type'



// TODO: get from edge (payment or DB)
export type CreditCardType = {
  number: string,
  expiration_month: number,
  expiration_year: number,
  code: number,
}


// TODO: get from edge (payment or DB)
export enum InvoiceStatusEnum {
  Created = 'Created',
  FullyPaid = 'FullyPaid',
  PartiallyPaid = 'PartiallyPaid',
  PaymentFailed = 'PaymentFailed',
}



// TODO: get from edge (payment or DB)
export type InvoiceType = {
  amount: number,
  billing_address: {
    name: string,
    street_address: string,
    city: string,
    state: string,
    zip: string,
  },
  invoice_number: string,
  memo: string,
  status: string,
}

export type CredentialsType = {
  companyName: string,
  username: string,
  password: string,
  token: string,
}

// More TrackingTypes Can be Added
export type TrackingInfo = {
  //analytics_provider : AnalyticsProviders
  tracking_type: SegmentTrackingTypes
  message: string,
  properties: any | undefined,
}

export type AuthSuccessType = {
  error?: Error | unknown,
  message: string,
  success: true,
  data?: any,
}

export enum AnalyticsProviders {
  Segment = "Segment"
}

export enum SegmentTrackingTypes {
  Page = "Page",
  Track = "Track"
}

export type PayInvoicePageType = {
  invoice: InvoiceType,
  invoice_fetched_failed: boolean,
  invoice_fetched_failed_message: string,
  invoice_payment_message: string,
  fetching_invoice: boolean,
  form_disabled: boolean,
  paying_invoice: boolean,
  paylink_url_hash: string,
  show_credit_card_input: boolean,
  show_invoice: boolean,
  show_invoice_payment_message: boolean,
  resetInvoicePage: () => void,
  fetchInvoice: (paylink_url_hash: string) => Promise<void>,
  invoiceIsFullyPaid: () => boolean,
  setupForms: () => void,
  showSpinner: () => boolean,
  handleCreditCardFormSubmission: (credit_card: CreditCardType) => void,
}

export type ApiGatewayPaylinkPostResponse = {
  data: {
    message: string,
    data: { paylink_url: string },
    success: boolean,
    error: { message: string }
  }
}

import { Helpers, CardHelpers } from "@omnimerchant/helpers"
const { isWrappedError, parseErrorMessage } = Helpers


export function dateToString(date : Date) : string {
  return date.toLocaleString(
    'en-US',
    {
      dateStyle: 'medium',
      timeStyle: 'short',
      hour12: true,
    }
  )
}


// NOTE/WARNING this allows paste which means non-numeric
// inputs can be pasted in - check AchInput to handle that
export function onlyAllowNumericInput(
  event : KeyboardEvent,
  allow_decimals : boolean = false,
) {
  const allowed_keys = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', // Numbers
    'Backspace', 'Delete', 'Home', 'End', 'Tab', 'Enter', // Navigation keys
    'Copy', 'Cut', 'Paste', // Clipboard keys
    'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', // Arrow keys
  ]

  if (allow_decimals)
    allowed_keys.push('.')

  if (
    !allowed_keys.includes(event.key.toString())
    && !event.ctrlKey // allows shortcuts - TODO: only works on win (metaKey needed)
  ) {
    event.preventDefault()
  }
}


export function removeNonDigits(
  str : string,
  allow_decimals : boolean = false,
) : string {
  // TODO: replace multiple periods
  // str.replace(/^([^.]*\.)(.*)$/, '$1$2')
  if (allow_decimals)
    return str.replace(/[^0-9.]/g, '')
  else
    return str.replace(/\D/g, '')
}


import Vue from 'vue'
import VueRouter from 'vue-router'
import PaylinkPage from './pages/PaylinkPage.vue'
import AboutPage from './pages/AboutPage.vue'
import ContactPage from './pages/ContactPage.vue'
import { initializeApp } from 'firebase/app'
import MerchantHeader from './components/MerchantHeader.vue'
import Alerts from './components/Alerts.vue'
import ProgressBar from './components/ProgressBar.vue'



const routes = [
  { name: 'about', path: '/about', component: AboutPage },
  { name: 'contact', path: '/contact', component: ContactPage },
  { name: 'paylink', path: '/paylink', component: PaylinkPage },
  { name: 'paylink', path: '/paylink/:id', component: PaylinkPage },
  { name: 'paylink', path: '/', component: PaylinkPage },
  { name: 'paylink', path: '', component: PaylinkPage },
]
const router = new VueRouter({
  mode: 'history',
  routes
})
Vue.use(VueRouter)



export default Vue.extend({
  components: {
    Alerts,
    MerchantHeader,
    ProgressBar,
  },


  data: function() {
    return {
      app: undefined,
      analytics: undefined,
      invoice: {
        amount: undefined,
        bill_address: '',
        items: [
          // at least one item is required
          { qb_id: '', price: null, units_sold: null, index: 0, removable: false }
        ],
        reference_number: '',
      },
      credit_card: {
        number: undefined,
        expiration_month: 6,
        expiration_year: new Date().getFullYear() % 100,
        code: 0,
      },
      show_create_tab: true,
      show_edit_tab: true,
    }
  },


  mounted: function() {
    this.setupFirebase()
  },


  methods: {
    showAllTabs: function() {
      this.show_create_tab = true
      this.show_edit_tab = true
    },

    hideCreateEditTabs: function() {
      this.show_create_tab = false
      this.show_edit_tab = false
    },

    handleCreditCardInput: function(
      credit_card : { code: number, expiration_month: number, expiration_year: number, number?: any}
    ) {
      this.credit_card.code = credit_card.code
      this.credit_card.expiration_month = credit_card.expiration_month
      this.credit_card.expiration_year = credit_card.expiration_year
      this.credit_card.number = credit_card.number
    },

    setupFirebase: function() {
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: "AIzaSyClYtUTNESRmTZWtpBhYn6JerIUTR5gwsw",
        authDomain: "omni-333806.firebaseapp.com",
        projectId: "omni-333806",
        storageBucket: "omni-333806.appspot.com",
        messagingSenderId: "366807359824",
        appId: "1:366807359824:web:45ffd0ff40753e18860c55",
        measurementId: "G-0FCDCY68N7"
      }

      // Initialize Firebase
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.app = initializeApp(firebaseConfig)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef
      // TODO: fix app.container is undefined
      // this.analytics = getAnalytics(app)
    },
  },


  router: router,
})


import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { track } from "@/services/TrackingService"
import {
  TrackingInfo,
  SegmentTrackingTypes,
} from "@/types/types"



@Component
export default class ContactPage extends Vue {

  mounted(): void {
    const trackingInfo: TrackingInfo = {
      tracking_type: SegmentTrackingTypes.Page,
      message: "Contact Page",
      properties: undefined,
    }
    track(trackingInfo)
  }
}

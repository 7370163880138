import { createEmptyCreditCard } from './placeholders'
import * as UiTypes from './ui_types'



export class CardInputController {
  public credit_card : UiTypes.CreditCard
    = createEmptyCreditCard()

  public masked_card_number : string = ''


  constructor() {}


  clearCardData() {
    this.masked_card_number = ''
    // TODO: the component has its own CC prop that needs
    // to be migrated over here
    const empty_cc = createEmptyCreditCard()
    this.credit_card.number = ''
    this.credit_card.expiration_month_one_indexed = 6
    this.credit_card.expiration_year_two_digit = empty_cc
      .expiration_year_two_digit
    this.credit_card.security_code = ''
  }


  getCardNumber() {
    // replace spaces that were used for masking
    return this.masked_card_number.replace(/\D/g, '')
  }


  maskCardNumber(card_number: string) {
    // Mask the input based on the card type
    // let masked_number
    const length = card_number.length
    let regex = /^(\d+)$/
    let replace = '$1'

    if (length <= 3) {
      regex = /^(\d+)$/
      replace = '$1'
    } else if (length == 4) {
      regex = /^(\d{4})$/
      replace = '$1 '
    } else if (length <= 7) {
      regex = /^(\d{4})(\d+)$/
      replace = '$1 $2'
    } else if (length == 8) {
      regex = /^(\d{4})(\d{4})$/
      replace = '$1 $2 '
    } else if (length <= 11) {
      regex = /^(\d{4})(\d{4})(\d+)$/
      replace = '$1 $2 $3'
    } else if (length == 12) {
      regex = /^(\d{4})(\d{4})(\d{4})$/
      replace = '$1 $2 $3 '
    } else if (length <= 15) {
      regex = /^(\d{4})(\d{4})(\d{4})(\d+)$/
      replace = '$1 $2 $3 $4'
    } else if (length == 16) {
      regex = /^(\d{4})(\d{4})(\d{4})(\d{4})$/
      replace = '$1 $2 $3 $4'
    } else if (length <= 19) {
      regex = /^(\d{4})(\d{4})(\d{4})(\d{4})(\d+)$/
      replace = '$1 $2 $3 $4 $5'
    }

    const masked_number = card_number
      .replace(
        regex,
        replace,
      )

    return masked_number
  }


  updateMaskedCardNumber() {
    const card_number = this.getCardNumber()
    this.masked_card_number = this.maskCardNumber(card_number)
  }
}

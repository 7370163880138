// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/blurred-background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#app-container {\r\n  background-color: #f5f5f3;\n}\n#app {\r\n  font-family: Avenir, Helvetica, Arial, sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  text-align: center;\r\n  color: #2c3e50;\r\n  background-color: #f5f5f3;\r\n  /* margin-top: 60px; */\n}\n#logo {\r\n  background-color: #f5f5f3;\n}\n.block {\r\n  display: block;\n}\nhtml {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\nbody {\r\n  background-color: transparent !important;\n}\r\n\r\n/* styling the nav is impossible from here :( */\r\n/* a.nav-link.active {\r\n  background-color: transparent;\r\n  border-bottom-color: #f5f5f3;\r\n} */\r\n", ""]);
// Exports
module.exports = exports;


import Vue from "vue"
import { Component } from "vue-property-decorator"
import CustomerAndMerchantInfo from '../components/CustomerAndMerchantInfo.vue'
import PaylinkInvoice from '../components/PaylinkInvoice.vue'
import PaylinkPaymentMethods from '../components/PaylinkPaymentMethods.vue'
import AchInput from "../components/omni/AchInput.vue"
import CardInput from "../components/omni/CardInput.vue"
import PaylinkReceipts from "../components/PaylinkReceipts.vue"
import { PaylinkPageController } from "./paylink/paylink_page_controller"
import { BusinesssTypes, Paylink } from "@omnimerchant/types"
import { DependencyContainer } from "../dependency_container"
import ViewStore from '@/stores/view_store'
import { PaylinkReceiptType, UiInvoice } from '@/types/paylink_ui_types'



@Component({
  components: {
  AchInput,
  CardInput,
  CustomerAndMerchantInfo,
  PaylinkInvoice,
  PaylinkPaymentMethods,
  PaylinkReceipts,
  },
  })
export default class PaylinkPage extends Vue {
  // WARNING move this into beforeCreate or created breaks some tests
  // idk why
  ctrl = PaylinkPageController.getController({
    paylink_client: DependencyContainer.get().paylink_client,
  })

  paylink : Paylink.Paylinks.PublicPaylink | {} = {}

  payment_methods_map = new Map()

  payment_methods_tracker = 0

  receipts : PaylinkReceiptType[] = []


  // TODO: test me, move into controller
  async created(): Promise<void> {
    try {
      ViewStore.progressbar.show()

      console.log("[PaylinkPage::created]")

      const pl_id = this.$route.params.id
      console.log('[created] loading paylink', pl_id)
      await this.ctrl.loadPaylink(pl_id)

      this.paylink = {
        ...this.paylink,
        ...this.ctrl.paylink,
      }

    } catch (error) {

      // TODO: replace paylink not to found message with the error message
      console.warn('error', error)
      ViewStore.alert.addWarning(
        `Paylink could not be loaded`,
        error
      )

    } finally {

      ViewStore.progressbar.hide()

    }
  }


  handleAmountSelection(val: { invoice: UiInvoice, amount: BusinesssTypes.Money}) {
    this.ctrl.handleAmountSelection(val)
  }


  handleInvoiceSelection(inv: UiInvoice) {
    this.ctrl.handleInvoiceSelection(inv)
  }


  hasPaylink() {
    return 'invoices' in this.paylink
    // return this.ctrl.paylink != undefined
    // return this.ctrl.hasPaylink()
  }


  hasUnpaidInvoices() {
    return this.ctrl.unpaid_invoices.length > 0
  }


  isInvoiceSelected() {
    return this.ctrl.isInvoiceSelected()
  }


  isReadyToPay() {
    return this.ctrl.isReadyToPay()
  }


  isSurchargeable() {
    // TODO: change to selected invoice instead of array
    // we only allow inv to be paid at a time
    return this.ctrl.paylink &&
    this.ctrl.selected_invoices.length > 0 &&
    this.ctrl.selected_invoices[0].surchargeable
  }


  async pay() {
    // TODO: debounce
    await this.ctrl.pay()
    this.receipts.length = 0
    this.receipts.push(
      ...this.ctrl.receipts
    )
  }


  // TODO: create PaymentMethod type with ID and payment amount
  updatePaymentAndPaymentMethods(
    payment_method: BusinesssTypes.ACHOrCard,
  ) {
    this.ctrl.payment_method = payment_method
    this.ctrl.calculateSurcharge(payment_method)
  }
}


import AchConstants from './ach_constants'
import * as UiTypes from './ui_types'
import { Helpers } from '@omnimerchant/helpers'
import Vue from 'vue'
import { createEmptyAch } from './placeholders'
import {
  onlyAllowNumericInput,
  removeNonDigits,
} from './ui_helpers'



export default Vue.extend({
  name: 'AchInput',


  props: {
    formReset: {
      default: () => { return 0 },
      type: Number,
    },

    required: {
      default: () => { return true },
      type: Boolean,
    },

    value: {
      default: () => { return {} },
      type: Object,
      validator: (val : UiTypes.ACH) => {
        return typeof(val) == 'object'
      }
    },
  },


  // emits: ['input'],


  data: function() {
    return {
      value_copy: this.value,
      UI_CONSTANTS: AchConstants,
    }
  },


  watch: {
    // watchs the formReset prop and clears the form
    // when it changes
    formReset: function() {
      this.value_copy = createEmptyAch()
    },
  },


  methods: {
    emitChange: function() {
      const ach: UiTypes.ACH = {
        ...this.value_copy,
        type: 'ACH', // not included by default
      }
      this.$emit('input', ach)
    },

    handleAccountNumberChange: function(value : string) {
      this.value_copy.account_number = removeNonDigits(value)
      this.emitChange()
    },

    handleAccountTypeChange: function(value : string) {
      this.value_copy.account_type = value
      this.value_copy.account_name = Helpers.capitalizeOneWord(
        value
      )
      this.emitChange()
    },

    handleRoutingNumberChange: function(value : string) {
      this.value_copy.routing_number = removeNonDigits(value)
      this.emitChange()
    },

    onlyAllowNumericInput: function(event : KeyboardEvent) {
      onlyAllowNumericInput(event)
    }
  },
})

import { BusinesssTypes } from "@omnimerchant/types"


const ACH_CONSTANTS = {
  account_number: {
    // min and max length don't work on number
    // inputs
    min_length: 1,
    required: true,
    type: 'text',
  },
  account_type: {
    options: Object.values(BusinesssTypes.AccountTypeValues),
    required: true,
  },
  routing_number: {
    min_length: 9,
    max_length: 9,
    required: true,
    type: 'text',
  },
}


export default ACH_CONSTANTS


import Vue from "vue"
import { PaylinkReceiptType } from '@/types/paylink_ui_types'
import { toPaymentMethodString } from "@/pages/paylink/paylink_page_helpers"
import { MoneyFactory } from "@omnimerchant/helpers"
import { capitalizeOneWord } from "@omnimerchant/helpers/dist/src/helpers"



export default Vue.extend({
  name: 'PaylinkReceipts',


  props: {
    receipts: { default: () => [], type: Array },
  },


  data: function() {
    return {
      receipt: {} as PaylinkReceiptType,
      // receipt_amounts: [],
      // payment_details: [],
    }
  },


  computed: {
    payment_details: {
      get() {
        // @ts-ignore // TSC does not recognize props
        console.log('[receipts_amounts]', this.receipts.length)
        return this.receipts.map(receipt => {
          return {
            Status: capitalizeOneWord(receipt.status),
            ID: receipt.transaction_id,
            charged: receipt.amount_applied.display_amount_with_currency,
            // TODO: handle undefined
            fees: receipt.fees.display_amount_with_currency,
            payment_method: toPaymentMethodString(receipt.payment_method)
          }
        })
      }
    },

    receipt_amounts: {
      get() {
        // @ts-ignore // TSC does not recognize props
        console.log('[receipts_amounts]', this.receipts.length)
        let total_applied = MoneyFactory.zero()
        let total_fees = MoneyFactory.zero()
        let total_taxes = MoneyFactory.zero()
        let total_billed = MoneyFactory.zero()

        // @ts-ignore // TSC does not recognize props
        for (const receipt of this.receipts) {
          total_applied = MoneyFactory.add(
            total_applied,
            receipt.amount_applied,
          )
          total_fees = MoneyFactory.add(
            total_fees,
            receipt.fees,
          )
          total_taxes = MoneyFactory.add(
            total_taxes,
            receipt.taxes,
          )
          total_billed = MoneyFactory.add(
            total_billed,
            receipt.total_amount_paid,
          )
        }
        const receipt_amounts = []
        receipt_amounts.push(
          {
            text: 'Applied to Balance',
            value: total_applied.display_amount_with_currency,
          },
          // TODO: test undefined
          {
            text: 'Fees',
            value: total_fees.display_amount_with_currency,
          },
          // TODO: test undefined
          {
            text: 'Taxes',
            value: total_taxes.display_amount_with_currency,
          },
          {
            text: 'Total Billed',
            value: total_billed.display_amount_with_currency,
          },
          // this total balance remaining TODO: calculate later
          // {
          //   text: 'Balance Remaining',
          //   value: this.receipt.balance_remaining.display_amount_with_currency,
          // }
        )
        return receipt_amounts
      },
    }
  },


  created: function() {
    console.log("PaylinkReceipt::created")
  },
})

// import BusinesssTypes from "@blogic/business_types"
import { BusinesssTypes } from "@omnimerchant/types"
// import ErpDepositTypes from "@erps/types/erp_deposit_types"
// import GatewayConfigTypes from "@gateways/config_interface/gateway_config_types"
// import GatewayDataTypes from "@gateways/gateway_data_types"
// import GatewayTypes from "@gateways/gateway_types"
// import GatewayVaultTypes from "@gateways/gateway_vault_types"



export type ACH = BusinesssTypes.ACH


export const AchAccountTypeValues = BusinesssTypes.AccountTypeValues


// TODO: rename me to DepositAccount
// export type Account = ErpDepositTypes.Account


export type DepositAccountDeprecated = {
    qb_id: string,
    full_name: string,
    type: string,
    number: string,
    record_node_name: string,
  }


export type BillingAddress = BusinesssTypes.Address


export type PalikePageController = {
  // TODO
}


export type CreditCard = BusinesssTypes.CreditCard


// export type ReceivedPayment = ErpDepositTypes.Payment & {
//   selected: boolean,
// }


// export const FeatureValues = GatewayConfigTypes.FeatureValues


export type InvoiceBillAddress = {
    bill_addr1: string,
    bill_addr2?: string,
    bill_addr3?: string,
    bill_addr4?: string,
    bill_addr5?: string,
    bill_city: string,
    bill_state: string,
    bill_postal_code: string,
    bill_country: string,
    note?: string,
  }


export type MiniCustomer = {
    full_name: string,
    qb_id: string,
  }


export type Customer = {
    bill_address_name: string,
    bill_address2: string,
    city: string,
    state: string,
    postal_code: string,
    full_name: string,
    phone: string,
    email: string,
    qb_id: string,
  }


// export type GatewayCustomer = GatewayDataTypes.VaultCustomer


// export type GatewayCustomerPaymentMethod = GatewayDataTypes.VaultPaymentMethod & {
//   label: string,
//   is_default?: boolean,
//   is_expired?: boolean,
// }


// export type GatewayCustomerSelectorEvent = {
//   customer: GatewayDataTypes.VaultCustomer,
//   payment_method: GatewayDataTypes.VaultPaymentMethod,
// }


export type MiniInvoice = {
    balance_remaining: number,
    customer_full_name: string,
    customer_qb_id: string,
    due_date: string,
    everything: string,
    qb_transaction_id: string,
    reference_number: string,
    // transaction_date: string,
  }


export type Invoice = InvoiceBillAddress & {
    qb_transaction_id: string,
    created_at: string,
    qb_transaction_number: string,
    customer_qb_id: string,
    customer_full_name: string,
    transaction_date: string,
    reference_number: string,
    bill_addr1: string | undefined,
    bill_addr2: string | undefined,
    bill_addr3: string | undefined,
    bill_addr4: string | undefined,
    bill_addr5: string | undefined,
    bill_city: string | undefined,
    bill_state: string | undefined,
    bill_postal_code: string | undefined,
    bill_country: string | undefined,
    bill_note: string | undefined,
    ship_addr1: string | undefined,
    ship_addr2: string | undefined,
    ship_addr3: string | undefined,
    ship_addr4: string | undefined,
    ship_addr5: string | undefined,
    ship_city: string | undefined,
    ship_state: string | undefined,
    ship_postal_code: string | undefined,
    ship_country: string | undefined,
    ship_note: string | undefined,
    is_pending: string,
    po_number: string | undefined,
    due_date: string,
    ship_date: string,
    subtotal: string,
    sales_tax_percentage: string,
    sales_tax_total: string,
    applied_amount: string,
    balance_remaining: string,
    memo: string | undefined,
    is_tax_included: string,
    items: Item[],
    record_node_name: string,
  }


export type MiniItem = {
    full_name: string,
    price: number,
    qb_id: string,
  }


export type Item = {
    line_id: string,
    qb_item_id: string,
    item_full_name: string,
    desc: string | undefined,
    // TODO: rename to units sold?
    quantity: number | undefined,
    rate: number | undefined,
    amount: number | undefined,
  }


// export type Gateways = GatewayTypes.Gateways


// export const GatewaysValues = GatewayTypes.GatewayValues


export type PaymentMethods = BusinesssTypes.PaymentMethods


export type PaymentMethodType = BusinesssTypes.PaymentMethodTypes


// TODO: consolidate with ReceintTypes.ReceitPresenter
export type Receipt = BusinesssTypes.Receipt & {
    applied_amount: BusinesssTypes.Money,
    gateway_id: string,
    erp_updated: boolean,
    process_date: Date,
    receipt_id: string,
  }


export type SelectedInvoiceType = {
    balance_remaining: number,
    billing_address: Pick<BusinesssTypes.Address,
      'city' | 'state' | 'street_address1' | 'street_address2' | 'zip'
    >,
    customer: {
      email: string,
      erp_id: string,
      name: string,
    },
    reference_number: string,
    // TODO: update these type to inherit from the
    // type store uses
    transaction_id: string,
    qb_transaction_id: string,
  }


export type VaultPayment = BusinesssTypes.VaultPayment


import { Money, MoneyFactory } from '@omnimerchant/helpers'
import Vue from 'vue'



export default Vue.extend({
  name: 'CustomerAndMerchantInfo',


  components: {
  },


  props: {
    address: {
      default: () => {
        return {
          name: 'Albert Holmes',
          street_address1: '1200 Forest',
          street_address2: 'Suite 2',
          city: 'Redwood',
          state: 'CA',
          zip: '94612',
        }
      },
      type: Object,
    },

    merchantContactInfo: {
      default: () => {
        return {
          name: 'Mer Chant',
          email: 'mer@chant.com',
          phone: '(510) 111 - 2222'
        }
      },
      type: Object,
    },

    minPayment: {
      default: () => {
        return MoneyFactory.createFromDollar(1020.34)
      },
      type: Money,
    },

    name: {
      default: () => { return 'Albert Holmes' },
      type: String,
    },

    totalDue: {
      default: () => {
        return MoneyFactory.createFromDollar(4231.98)
      },
      type: Money,
    },
  },


  data: function() {
    return {}
  },


  mounted: function() {
  },


  methods: {
  },
})
